import { useLocation } from 'react-router-dom'
import { Menu, MenuOpen } from '@mui/icons-material'
import { Box, Divider, Hidden } from '@mui/material'
import IntelligentHealth from '../../assets/intelligentHealth.svg'
import useLayout from '../../hooks/useLayout'
import type { NavSectionProps } from './NavSection'
import NavSection from './NavSection'

interface Props {
  id: string
  sections: Array<NavSectionProps>
}

const IntelligentHealthImage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <img
        src={IntelligentHealth}
        style={{ height: 'auto', width: '80%', paddingBottom: 30, paddingTop: 15 }}
        alt={'WalkingGirl'}
      />
    </Box>
  )
}

export const SidebarContent = ({ sections, id }: Props) => {
  const { isSidebarOpen, setIsSidebarOpen } = useLayout()
  const location = useLocation()

  return (
    <Box
      id={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 64px)',
        justifyContent: 'space-between',
        overflow: 'scroll',
      }}
      data-testid="side-navbar"
    >
      <Box>
        <Hidden lgDown>
          {!isSidebarOpen && (
            <Box sx={{ m: 2, mb: 6 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Menu onClick={() => setIsSidebarOpen(true)} />
            </Box>
          )}
        </Hidden>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            width: '100%',
            top: 0,
            backgroundColor: 'white',
            zIndex: 2000,
          }}
        >
          {isSidebarOpen && (
            <>
              <Hidden lgDown>
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingTop: 15,
                    paddingRight: 15,
                  }}
                >
                  <MenuOpen onClick={() => setIsSidebarOpen(false)} />
                </Box>
                <Divider />
              </Hidden>
              <Hidden lgUp>
                <Box sx={{ py: 2 }} />
              </Hidden>
            </>
          )}
        </Box>

        <Box sx={{ p: 2, paddingTop: 0, mt: '40px' }}>
          {sections.map((section, index) => (
            <NavSection
              key={`${section.pathname}${index}`}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 0,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Box>
      {isSidebarOpen && <IntelligentHealthImage />}
    </Box>
  )
}
